import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { getJobByJobId } from '../../utils/query';

import styles from './Job.module.css';

const statuses = {
	pending_payment: 'is preparing for the extraction.',
	in_progress: 'is in progress of extracting all the Google Maps reviews. We will send you an email when we finish.',
	extracted: 'has extracted all the Google Maps reviews. Now we are creating the downloadable file. Come back soon to download it.',
	completed: 'is completed. We have created an excel file with all the reviews for you:',
};

export default function Job() {
	document.title = 'Job extraction - Extractor';

	let { jobId } = useParams();
	const [job, setJob] = useState(null);

	const [errorMessage, setErrorMessage] = useState(null);

	const getJob = useCallback(async () => {
		const result = await getJobByJobId(jobId);
		if (result.error) return setErrorMessage(result.error);

		document.title = result.name + ' - Extractor';
		return setJob(result);
	}, [jobId]);

	useEffect(() => {
		getJob();
	}, [getJob]);

	return (
		<>
			<div className={styles.title}>{job?.name || 'Job extraction'}</div>

			{ errorMessage && <div className={styles.error}>{errorMessage}</div>}

			{ job &&
				<>
					<div className={styles.description}>
						Your job {statuses[job.status] || 'in progress'}
					</div>

					{ job.status === 'completed' &&
						<>
							<a href={job.download_url} style={{ textDecoration: 'none' }}>
								<div className={styles.button}>Download results</div>
							</a>
						</>
					}
				</>
			}
		</>
	);
}
