import { useEffect } from 'react';
import styles from './Finder.module.css'

export default function Faq() {
	document.title = 'Place ID Finder - Extractor';

	useEffect(() => {
		const element = document.getElementById('app_wrapper');
		element.style.maxWidth = '800px';		
	}, []);

	return (
		<>
			<div className={styles.title}>Place ID Finder</div>

			<div className={styles.answer}>
				<center>
					Search for any place on Google Maps and get its Place ID. This ID will be needed to extract the reviews.
				</center>
			</div>

			<iframe
				title={'Place ID Finder'}
				src="https://geo-devrel-javascript-samples.web.app/samples/places-placeid-finder/app/dist/"
				className={styles.iframe}
			></iframe>

			<div className={styles.back}>
				<a href="/">Create an extraction</a>
			</div>
		</>
	);
}
