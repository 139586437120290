import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8001';

export async function query({ method, url, data }) {
	let request;

	try {
		request = await axios({
			method,
			url: API_ENDPOINT + url,
			data,
		});
	} catch (e) {
		const error = e?.response?.data;
		if (error) return error;

		console.log(e);
		alert('A network error occurred');
		return null;
	}

	const requestData = request?.data;

	if (!requestData) return null;

	return requestData;
}

export async function createForm({ email, name, placeIds }) {
	return await query({
		method: 'post',
		url: '/jobs',
		data: {
			email,
			name,
			placeIds,
		},
	});
}

export async function getJobByJobId(jobId) {
	return await query({
		method: 'get',
		url: '/jobs/' + jobId,
	});
}
