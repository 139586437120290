import { useState, useRef } from 'react';
import { Circle } from 'rc-progress';

import styles from './Form.module.css';

import { createForm } from '../../utils/query';

export default function Form() {
	const [loading, setLoading] = useState(false);

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [placeIds, setPlaceIds] = useState('');

	const [errorMessage, setErrorMessage] = useState(null);

	const [formResults, setFormResults] = useState(null);

	const [progress, setProgress] = useState(0);
	const interval = useRef(null);

	async function createPriceQuote() {
		setLoading(true);
		setErrorMessage(null);

		setProgress(0);
		startProgress();

		if (!email || !placeIds || !name) {
			setLoading(false);
			return setErrorMessage('Missing fields');
		}

		const result = await createForm({ email: email.toLowerCase().trim(), name: name.trim(), placeIds });
		if (!result || result.error) {
			setLoading(false);
			return setErrorMessage((result?.error || 'An error occurred'));
		}

		setLoading(false);
		setFormResults(result);
	}

	function startProgress() {
		setProgress(0);

		const totalPlaces = placeIds.trim().split('\n').filter(p => p).length;
		const factor = 0.14;

		interval.current = setInterval(() => {
			if (progress >= 100) clearInterval(interval.current);
			setProgress(val => {
				if (val >= 100) {
					clearInterval(interval.current);
					return 100;
				}
					return val + (100 / totalPlaces);
				})
		}, factor * 1000)
	}
	if (formResults) {
		return (
			<>
				<div className={styles.title}>Your quote</div>
				<div className={styles.description} style={{ textAlign: 'left', marginBottom: '40px' }}>
					You will receive all the Google Maps reviews for <b>{formResults.count} {(formResults.count === 1) ? 'place' : 'places'}</b> with a total of&nbsp;
					<b>{formResults.total_reviews} {(formResults.total_reviews === 1) ? 'review' : 'reviews'}</b> in an excel format.
					<br/><br/>
					The cost is of <b>{formResults.price.amount}€</b>, and after its payment, we will automatically extract the reviews and make them available for you.
					<br/><br/>
					This process can take a while (depending on how many place IDs will be extracted). We will send you emails of the extraction status to <b>{email.toLowerCase().trim()}</b>
					&nbsp;so you get updated.
				</div>

				{ formResults.payLink.includes('http') &&
					<a href={formResults.payLink} style={{ textDecoration: 'none' }}>
						<div className={styles.button}>Continue and start the extraction</div>
					</a>
				}

				{ !formResults.payLink.includes('http') &&
					<code>{formResults.payLink}</code>
				}
			</>
		);
	}

	return (
		<>
			<div className={styles.title}>Create an extraction</div>
			<div className={styles.description} style={{ marginTop: '-20px' }}>
				Get all the Google Maps reviews for the places you want, including the rating, comment, and replies.
				<br/><a href="/faq">Learn more</a>
			</div>

			{ errorMessage && <div className={styles.error}>{errorMessage}</div>}

			<input value={email} spellCheck={false} autoComplete={'none'} placeholder={'Your email'} autoFocus={true} onChange={(e) => setEmail(e.target.value)} />
			<input value={name} spellCheck={false} autoComplete={'none'} placeholder={'Extraction name'} onChange={(e) => setName(e.target.value)} />
			<textarea value={placeIds} spellCheck={false} autoComplete={'none'} placeholder={'Place IDs (one per line)'} rows={10} onChange={(e) => setPlaceIds(e.target.value)} />

			{ loading && <div className={styles.button + ' ' + styles.buttonDisabled}>
					Analyzing places to create quote
					<Circle percent={progress} strokeWidth={10} strokeColor="#fff" style={{ height: '18px', verticalAlign: '-3px', marginLeft: '10px' }} />
				</div> }
			{ !loading && <div className={styles.button} onClick={createPriceQuote}>Get price quote</div> }
		</>
	);
}
