import styles from './Faq.module.css'

export default function Faq() {
	document.title = 'Frequently Asked Questions - Extractor';

	return (
		<>
			<div className={styles.title}>Frequently Asked Questions</div>

			<div className={styles.question}>Why would do you need Extractor</div>
			<div className={styles.answer}>
				Getting Google reviews is hard. They are limitations and the extraction 
				process is hard.
				<br/><br/>
				Extractor automates the extraction process and gives you an excel file
				so you can analyze it easily.
			</div>

			<div className={styles.question}>What is a Place ID</div>
			<div className={styles.answer}>
				Place IDs uniquely identify a place in the Google Places database and on Google Maps.
				One can look like this: <code>ChIJrTLr-GyuEmsRBfy61i59si0</code>
				<br/><br/>
				👉 You can find the Place ID for any location with <a href="/place-id-finder">our tool</a>
			</div>

			<div className={styles.question}>What will you get</div>
			<div className={styles.answer}>
				You will get an excel file with the following fields: 
				placeId, address, url, reviewer, rating, comment, commented_at (date),
				hasReply, reply, replied_at (date).
			</div>

			<div className={styles.question}>When will you get the file</div>
			<div className={styles.answer}>
				It depends on the number of places you submit and their number of reviews.
				Places with high number of reviews will take longer. After the payment, the
				process will start and you will receive an email when the extraction finishes.
			</div>

			<div className={styles.question}>How is the price calculated</div>
			<div className={styles.answer}>
				Pricing is calculated depending on the number of places you submit and the
				number of reviews these places have. Extracting reviews from places with 
				a lot of reviews will cost more.
				<br/><br/>
				When you get your quote, before paying, you will get the number of places, the
				number of reviews, and the price.
			</div>

			<div className={styles.question}>More questions?</div>
			<div className={styles.answer}>
				Please email us at support@extractor.me. We will be happy to
				answer more questions.
			</div>

			<div className={styles.back}>
				<a href="/">Create an extraction</a>
			</div>
		</>
	);
}
