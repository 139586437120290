import { BrowserRouter, Routes, Route } from 'react-router-dom';

import styles from './App.module.css';

import FormPage from './pages/Form';
import JobPage from './pages/Job';
import FaqPage from './pages/Faq';
import PlaceIdFinderPage from './pages/Finder';
import CancelPage from './pages/Cancel';

const isLocal = !process.env.REACT_APP_API_ENDPOINT;

export default function App() {
	return (
		<>
			<div className={styles.bodyWrapper} id={'app_wrapper'}>
				<div className={styles.logoWrapper}>
					<a href="/">
						<div className={styles.logo}></div>
					</a>
				</div>

				{ isLocal && <div className={styles.local}>Local</div> }

				<div className={styles.box}>
					<BrowserRouter>
						<Routes>
							<Route path={'/'} element={<FormPage />} />
							<Route path={'/job/:jobId'} element={<JobPage />} />
							<Route path={'/faq'} element={<FaqPage />} />
							<Route path={'/place-id-finder'} element={<PlaceIdFinderPage />} />
							<Route path={'/canceled'} element={<CancelPage />} />
							<Route path={'*'} element={<center>Page not found</center>} />
						</Routes>
					</BrowserRouter>
				</div>

				<div className={styles.footer}>
					<div className={styles.placeIdToolBottom}>
						<a href="/place-id-finder">👉 <strong>Tool:</strong> Find the Place ID for any location</a>
					</div>

					<div style={{ opacity: 0.3, marginTop: '20px' }}>
						Questions? Read the <a href="/faq">frequently asked questions</a><br/> or email us at support@extractor.me
					</div>
				</div>
			</div>
		</>
	);
}
